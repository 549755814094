<template>
  <div>
    <songs-list lang="RU"></songs-list>
  </div>
</template>

<script>
import SongsList from './SongsList.vue'

/*eslint-disable */
export default {
  components: {
    SongsList,
  },
  data() {
    return {}
  },
}
</script>
