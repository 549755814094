<template>
  <div>
    <section id="card-content-types">
      <h1 class="mt-3">
        Песни {{ lang }}
      </h1>
      <b-row>
        <b-col cols="12">
          <b-card-actions
              ref="cardAction"
              title="Только названия"
              actionCollapse
          >
            <b-row>
              <b-col cols="12">
                <div>
                  <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      inline
                      v-model="showOnlyTitles"
                  >
                    Только названия
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>

        <b-col
            md="12"
            lg="12"
        >
          <!-- table -->
          <vue-good-table
              class="songs-list"
              :columns="columns"
              :rows="songsList"
              :search-options="{
                enabled: false,
                }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: false, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: false,
              }"
              :line-numbers="!showOnlyTitles"
          >
            <template
                slot="table-row"
                slot-scope="props"
            >

              <!-- Column: Name -->
              <span
                  v-if="props.column.field === 'title'"
                  class="text"
              >

                <b-avatar v-if="!showOnlyTitles"
                    :src="props.row.cover"
                    class="mx-1"
                />
                <span class="text">{{ props.row.title }}</span>
              </span>

              <!-- Column: Release -->
              <span v-else-if="props.column.field === 'release_date'">
                {{ props.row.release_date | formatDate }}
              </span>

              <!-- Column: Votes -->
              <span
                  v-else-if="props.column.field === 'votes'"
                  class="text-nowrap"
              >

                <!-- avatar group -->
                <b-avatar-group
                    class=""
                    size="34px"
                >
                  <b-avatar
                      v-for="vote in props.row.votes"
                      :key="vote.id"
                      v-b-tooltip.hover.bottom="vote.user.name + ' - ' + vote.rating.toString()"
                      :src="vote.user.image"
                      class="pull-up"
                      :badge="vote.rating.toString()"
                      :badge-variant="(vote.rating <= 4) ? 'danger' : (vote.rating <= 7) ? 'warning' : 'success'"
                  />
                  <h6 class="align-self-center cursor-pointer ml-1 mb-0">
                    ({{ props.row.votes.length }})
                  </h6>
                </b-avatar-group>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>

        </b-col>
      </b-row>
    </section>

  </div>
</template>

<script>
import { BAvatar, BAvatarGroup, BCol, BFormCheckbox, BMediaBody, BRow, VBTooltip } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'

/*eslint-disable */
export default {
  components: {
    BCardActions,
    BFormCheckbox,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      search_query: '',
      songsList: [],
      filter: {
        onlyUnrated: false,
      },
      showOnlyTitles: false,
      columns_full: [
        {
          label: 'Название',
          field: 'title',
        },
        {
          label: 'Релиз',
          field: 'release_date',
        },
        {
          label: 'Оценки',
          field: 'votes',
        },
        {
          label: 'Результат',
          field: 'result',
        },
      ],
      columns_short: [
        {
          label: 'Название',
          field: 'title',
        },
      ],
      columns: [],
    }
  },
  props: ['lang'],
  filters: {
    formatDate: function (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    },
  },
  watch: {
    showOnlyTitles() {
      this.columns = this.showOnlyTitles ? this.columns_short : this.columns_full;
    }
  },
  methods: {},
  created() {
    this.columns = this.columns_full;
    this.$http.get('/songs/results?lang=' + this.lang).then(res => {
      this.songsList = res.data.data
    })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>